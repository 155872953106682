@import "../../common/scss/spacing";
.ts-user-login {
  display: none;
}

.ts-logged-in {
  $sidbar-width: 400px;
  // padding-top: 98px;
  padding-top: 75px;
  display: grid;
  grid-template-columns: $sidbar-width auto;

  .ts-main {
    // min-height: calc(100vh - 505px);
    // width: calc(100% - 2rem);
    min-height: calc(100vh - 30.125rem);
  }

  //   .container {
  //     max-width: calc(100% - $sidbar-width) !important;
  //   }
  .ts-user-login {
    display: block;
  }
  .ts-user-not-login {
    display: none;
  }

  // &--not-side-nav {
  //   grid-template-columns: auto;
  //   padding: 0;
  // }
  @media (max-width: 1400px) {
    grid-template-columns: auto;

    // padding-top: 1.5rem;

    .ts-main {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    .ts-main {
      // padding: 2.5rem 0;
      min-height: calc(100vh - 1050px);
    }
  }
}

.ts-rtl {
  text-align: right;
}

.ts-page-content {
  padding: 2rem 1.5rem;
  @media (max-width: 1400px) {
    padding: 3rem 0;
  }
}

.ts-padding-top-negative-header {
  padding-top: 76px;
}
