.ts-inspiring-stories {
  &-modal {
    .modal-content {
      padding: 16px 16px 50px;
      background-color: #f1f1f1 !important;
      border-radius: clamp(20px, 2.5vw, 36px);
      // .video-wrapper {
      //   border-radius: clamp(20px, 2.5vw, 36px);
      //   overflow: hidden;
      // }
    }
  }
}

.ts-upload-story {
  // background-color: #f7f7f7;
  &__main {
    max-width: 1212px;
    margin: 0 auto;
  }
  .btn {
    background-color: #f990c2;
    color: white;
  }
  // input[type="file"] {
  //   display: none;
  // }
  .form-file {
    position: relative;
    #formFile {
      // visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
