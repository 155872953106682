@import "../../common/scss/colors";
.ts-date {
  text-align: center;
  background-color: $primary-03;
  height: auto;
  padding: 3rem 1.25rem;
  color: white;
  font-weight: bold;
  height: 100%;
  display: grid;
  place-items: center;
  @media (max-width: 991px) {
    min-height: 590px;
  }
  @media (max-width: 767px) {
    min-height: 450px;
  }
}
