@import "../../common/scss/colors";

[data-tabs-courses="1"] {
  .nav-item {
    width: 100%;
  }
}
[data-tabs-courses="2"] {
  .nav-item {
    width: calc(100% / 2);
  }
}
[data-tabs-courses="3"] {
  .nav-item {
    width: calc(100% / 3);
  }
}
[data-tabs-courses="4"] {
  .nav-item {
    width: calc(100% / 4);
  }
}
[data-tabs-courses="5"] {
  .nav-item {
    width: calc(100% / 5);
  }
}
[data-tabs-courses="5"] {
  .nav-item {
    width: calc(100% / 5);
  }
}
[data-tabs-courses="6"] {
  .nav-item {
    width: calc(100% / 6);
  }
}
[data-tabs-courses="7"] {
  .nav-item {
    width: calc(100% / 7);
  }
}
[data-tabs-courses="8"] {
  .nav-item {
    width: calc(100% / 4);
  }
}
[data-tabs-courses="9"] {
  .nav-item {
    width: calc(100% / 4);

    &:nth-last-child {
      width: 100%;
    }
  }
}
[data-tabs-courses="10"] {
  .nav-item {
    width: calc(100% / 5);
  }
}

.ts-lessons-tabs {
  .nav-tabs {
    position: relative;
    width: 100%;

    padding: 0;
    margin-bottom: 0 !important;
    .nav-item {
      button {
        width: 100%;
        border-radius: 0;
      }

      @media (min-width: 992px) {
        &:not(:last-child) {
          button {
            border-right: 0;
          }
        }
      }
      @media (max-width: 991px) {
        width: 100%;

        &:not(:last-child) {
          button {
            border-bottom: none;
          }
        }
      }
    }
  }

  .tab-content {
    border: 2px solid $primary-02;
    border-top: 0;
    padding: 1.25rem;
    position: relative;
    top: -2px;
  }
  .nav-link {
    color: $gray-01;
    font-weight: bold;
    border: 2px solid $primary-02;
    // background-color: $gray-07;

    color: $gray-05;
    background-color: $white;
    &:not(:last-child) {
      border-left: 0 !important;
    }
    &:hover {
      border: 2px solid $primary-02;
    }
  }
  & .nav-link.active,
  & .nav-item.show .nav-link {
    color: white;
    background-color: $primary-02;
    border-color: $primary-02;
  }
}

.ts-lesson-card {
  width: 161px;
  border: 2px solid $primary-02;
  &__img {
    width: 100%;
  }
  &__body {
    padding: 5px 3px;
    font-size: 14px;
    p {
      height: 43px;
      overflow: hidden;
    }
  }
}

.ts-rtl {
  .ts-lessons-tabs {
    .nav-tabs {
      .nav-item {
        @media (min-width: 992px) {
          button {
            border: 2px solid $primary-02;
          }

          &:not(:first-child) {
            button {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}
