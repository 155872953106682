@import "./colors";
.dropdown {
  background-color: $primary-01;
  color: $white;
  // width: 300px;
  &-toggle {
    width: 100%;
    padding: 9px 75px 9px 20px !important;
    &::after {
      position: absolute;
      right: 20px;
      top: 48%;
      font-size: 25px;
      // transform: translateY(-50%);
    }
  }
  .nav-link {
    color: $white;
  }
}

.breadcrumb {
  margin-bottom: 0;
  &-item {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      color: $primary-07;
      font-weight: bold;
      font-size: 18px;
    }
    &::before {
      content: "" !important;
      background-image: url("../../assets/icons/Icon-Arrow-Right.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 100%;
      top: 50%;
      padding-right: 0;
      margin-right: 0.5rem;
    }

    // &:nth-child(1) {
    //   &::before {
    //     display: none;
    //   }
    // }
  }
}
