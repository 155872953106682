@import "../../common/scss/colors";
@import "../../common/scss/typography";
@import "../../common/scss/spacing";
$ts-course-card-radius: clamp(35px, 6vw, 100px);
.ts-courses-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(0.8rem, 3vw, 4.75rem);

  $ts-course-card-radius: clamp(35px, 6vw, 100px);

  .ts-course-card {
    border-radius: $ts-course-card-radius;

    &:nth-child(1) {
      border-top-left-radius: 0;
    }
    &:nth-child(3) {
      border-top-right-radius: 0;
    }

    // &:nth-last-child(3) {
    //   border-radius: $ts-course-card-radius;
    //   border-bottom-left-radius: 0;
    // }
    // &:last-child {
    //   border-radius: $ts-course-card-radius;
    //   border-bottom-right-radius: 0;
    // }
  }

  &--2-cards {
    grid-template-columns: repeat(2, 1fr);

    .ts-course-card {
      &:nth-last-child(2) {
        border-radius: $ts-course-card-radius;
      }
      &:nth-last-child(3) {
        border-radius: $ts-course-card-radius;
      }
    }
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);

    .ts-course-card {
      &:nth-child(1) {
        border-top-left-radius: 0;
      }
      &:nth-child(2) {
        border-top-right-radius: 0;
      }
      &:nth-child(3) {
        border-radius: $ts-course-card-radius;
      }
    }

    &--2-cards {
      grid-template-columns: repeat(2, 1fr);

      .ts-course-card {
        &:nth-child(2) {
          border-radius: $ts-course-card-radius;
        }
      }
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);

    .ts-course-card {
      &:nth-child(1) {
        border-top-left-radius: 0;
      }
      &:nth-child(2) {
        border-radius: $ts-course-card-radius;
      }
      &:nth-child(3) {
        border-radius: $ts-course-card-radius;
      }

      &:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }
}

.ts-logged-in {
  .ts-courses-group {
    gap: clamp(0.5rem, 2vw, 2rem);
    @media (max-width: 1600px) and (min-width: 1401px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.ts-course-card {
  $self: &;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  text-align: center;
  padding: $spacing-11 $spacing-05 $spacing-08;
  // padding: $spacing-08 $spacing-05;
  &__center-img {
    width: 104px;
    max-width: 100%;
  }
  &__delete,
  &__heart,
  &__cart {
    height: 35px;
    width: auto !important;
  }
  &__title {
    color: $gray-04;
  }
  &__desc {
    color: #9c9da1;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 75px;
    line-height: 25px;
    margin: 0 auto;
    font-size: 14px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__btn {
    background-color: $gray-07;
    border-radius: 50rem;
    font-weight: bold;
    color: $white;
    padding: 7px 20px 8px;
    font-size: $heading-6;
    width: 100%;
    max-width: 260px;
  }

  &--primary {
    position: relative;
    padding: 40px;
    background-color: $primary-04;
    min-height: clamp(375px, 35vw, 420px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    color: white;
    & > * {
      position: relative;
      z-index: 2;
    }
    &::after {
      content: " ";
      position: absolute;
      height: 500px;
      width: 500px;
      left: 0;
      bottom: 0;
      transform: translate(-36%, 31%) rotate(-11deg);
      background-image: url("../../assets/images/flower-bg-white.svg");
      background-size: cover;
    }
    #{ $self }__title {
      color: $white;
    }
    #{ $self }__btn {
      color: $white;
      background-color: transparent;
      border: 2px solid $white;
      transition: color, background-color, 300ms ease-in-out;
      &:hover {
        background-color: $white;
        color: $primary-04;
      }
    }
    p {
      color: inherit;
    }
  }

  @media (max-width: 991px) {
    &--primary {
      // min-height: 426px;
      padding: 30px 20px;
    }
  }
  @media (max-width: 375px) {
    &__btn {
      padding: 5px 10px 6px;
    }
    // &--primary {
    //   min-height: 326px;
    // }
  }
}

.btn-change {
  padding: 0;
  border: none;
  &:hover {
    border: none;
  }
}

.ts-rtl {
  .ts-courses-group {
    .ts-course-card {
      border-radius: $ts-course-card-radius;
      &:nth-child(1) {
        border-top-right-radius: 0;
      }
      &:nth-child(3) {
        border-bottom-left-radius: 0;
      }

      // &:nth-last-child(3) {
      //   border-radius: $ts-course-card-radius;
      //   border-bottom-left-radius: 0;
      // }
      // &:last-child {
      //   border-radius: $ts-course-card-radius;
      //   border-bottom-right-radius: 0;
      // }
    }
  }
}
