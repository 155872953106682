@import "../../common/scss/colors";
.ts-promo-gift-header {
  background-color: $primary-01;
  text-align: center;
  padding: 50px 12px;
  &__logo {
    width: 110px;
    max-width: 100%;
  }
}

// body {
//   padding-top: 0;
// }
