@import "../../common/scss/colors";
.ts-purchase-history {
  margin-block: 22px;
  &__table-container {
    border-radius: 20px;
    overflow: hidden;
    background-color: $primary-07;
    padding: 1.875rem;
  }

  .btn-invoice {
    color: white;
    border: 1px solid white;
    padding: 0.4rem 1.4rem;
    background-color: $primary-04;
  }
  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  thead {
    th,
    td {
      padding: 1.1rem 0.8rem;
      font-size: clamp(1.125rem, 3vw, 1.25rem);
      font-weight: 400;
      background-color: $primary-04;
      &:first-child {
        border-radius: 0.75rem 0 0 0.75rem;
      }
      &:last-child {
        border-radius: 0 0.75rem 0.75rem 0;
      }
    }
  }

  table tr {
    // background-color: #f8f8f8;
    // border: 1px solid #ddd;
    padding: 0.35em;
  }

  table th,
  table td {
    padding: 0.875rem;
    // text-align: center;
  }

  table {
    tbody {
      td,
      th {
        font-weight: bold;
      }
    }
  }

  table th {
    // font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 991px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid rgba(255, 255, 255, 0.562);

      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid $primary-01;
      display: block;
      font-size: 0.8em;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    table td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      //   float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}
