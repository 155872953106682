// @import "../../common/scss/colors";
.ts-bonus-material-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  &__video {
    width: 300px;
  }
}
