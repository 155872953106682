@import "../../common/scss/colors";
.ts-program-menu-title-card {
  background-color: white;
  padding: 60px 20px;
  text-align: center;
  border: 2px solid white;
  &--active {
    border-color: $primary-04;
  }
}
