@import "../../common/scss/spacing";
@import "../../common/scss/colors";
.ts-profile {
  padding: $spacing-14 0;
  &__main {
  }

  .ts-form-item {
    width: calc(50% - 1.5rem);
    &-group {
      column-gap: 1.5rem;
      row-gap: 2rem;
    }
  }

  .form {
    &-control,
    &-select {
      background-color: $gray-12;
      min-height: 3rem;
    }
  }

  @media (max-width: 575px) {
    .ts-form-item {
      width: 100%;
    }
  }
}
