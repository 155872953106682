.btn {
  &-primary-01,
  &-primary-02,
  &-primary-03,
  &-primary-04,
  &-primary-05,
  &-primary-06,
  &-primary-07 {
    &:focus {
      color: white !important;
    }
  }
  &-primary-01 {
    width: 195px;
    max-width: 100%;
    color: white;
    font-size: 1.2rem;
    a {
      color: white;
      text-decoration: none;
    }
    &:hover {
      color: white;
    }
  }
  &-primary-02 {
    width: 195px;
    max-width: 100%;
    color: white;
    font-size: 1.2rem;
    &:hover {
      color: white;
    }
  }
  &-primary-04,
  &-primary-07 {
    padding: 8px 2rem 9px !important;
    max-width: 100%;
    color: white;
    font-size: 1.2rem;
    &:hover {
      color: white;
    }
  }
  &-white {
    background-color: $white;
    color: $primary-03 !important;
    border: 2px solid $white;
    transition: color, background-color, border-color, 300ms ease-in-out;
    &:hover {
      color: $white !important;
      border: 2px solid $white;
    }
  }
  &--border-white {
    border: 2px solid $white;
    box-shadow: rgba(149, 157, 165, 0.3) 0px 3px 6px;

    &:hover {
      border: 2px solid $white;
    }
  }
  &-lg {
    font-size: 2rem;
    min-height: 4.1875rem;
  }
  &--border-white {
    border: 2px solid $white;
  }
}

.close-btn {
  border: none;
  &:hover {
    border: none;
  }
}
