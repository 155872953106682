.mw {
  &-900 {
    max-width: 900px;
  }

  &-lg {
    @media (min-width: 991px) {
      &-700 {
        max-width: 700px;
      }
      &-800 {
        max-width: 800px;
      }
      &-900 {
        max-width: 900px;
      }
    }
  }
}

$nav-bar-height: 76px;
