@import "../../common/scss/colors";
@import "../../common/scss/spacing";
@import "../../common/scss/typography";

.ts-profile-modal {
  .modal {
    &-content {
      background-color: transparent;
      border: none;
    }
    &-body {
      padding: 0;
    }
  }
  &-container {
    position: relative;
    background-color: $gray-08;
    max-width: 790px;
    min-height: 790px;
    margin: 0 auto;
    padding: $spacing-13 $spacing-11;
    .close-btn {
      position: absolute;
      right: 25px;
      top: 25px;
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      color: $primary-01;
      font-weight: bold;
      padding: 0;
    }
  }

  &__list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      font-size: $heading-4;
      &:not(:last-child) {
        margin-bottom: $spacing-06;
      }
      a {
        color: $gray-05;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 575px) {
    &-container {
      min-height: 600px;
      padding: $spacing-13 $spacing-06 $spacing-10;
    }
  }
}

// .ts-rtl {
//   .ts-profile-menu {
//     li {
//       text-align: right !important;
//     }
//   }
// }
