@import "/src/common/scss/colors";

.ts-quiz-section {
  padding-inline: 3rem;
}

.ts-quiz-question {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 4rem;
  padding: 2rem 3rem;

  @media (max-width: 767px) {
    border-radius: 0.5rem;
    padding: 1rem;
  }
}

.ts-check {
  .form-check-input {
    border-radius: 0.15rem !important;
    &:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    }
  }
}

.ts-quiz-submitted {
  .ts-correct-ans {
    color: $success;
    box-shadow: $success !important;
    span {
      color: $success;
    }
    .form-check-input {
      &:checked {
        background-color: $success !important;
        border-color: $success !important;
        box-shadow: none !important;
        & + .form-check-label {
          color: $success;
        }
      }
    }
  }
  .form-check-input {
    &:checked {
      background-color: red !important;
      border-color: red !important;
      box-shadow: none !important;
      & + .form-check-label {
        color: red;
      }
    }
  }

  .form-control {
    border-color: $success !important;
    box-shadow: none !important;
  }
}

.ts-rtl {
  .form-check {
    padding-left: 0 !important;
    padding-right: 2rem !important;

    .form-check-input {
      float: right;
      margin-left: auto;
      margin-right: -1.5em;
    }
  }
}

@media (max-width: 1400px) {
  .ts-quiz-section {
    padding-inline: 0 !important;
  }
}
