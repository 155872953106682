@import "../../common/scss/spacing";
@import "../../common/scss/colors";
.ts-technical-support {
  padding: $spacing-06 0 $spacing-12;
  .form-control {
    background-color: $gray-08;
  }
  @media (min-width: 1401px) {
    padding: $spacing-06 7vw $spacing-12;
  }
}
