@import "../../common/scss/spacing";
@import "../../common/scss/colors";
.ts-lessons {
  list-style: none;
  padding-left: 0;
  display: grid;
  gap: $spacing-13;
}
.ts-vimeo-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ts-vimeo-video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.ts-schedule-thumbnail {
  position: relative;
  min-height: 150px;
  aspect-ratio: 16 / 9;
  border: 2px solid $primary-02;
  display: grid;
  place-items: center;
  isolation: isolate;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: -1;
  }
}
