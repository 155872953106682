@import "../../common/scss/colors";
.swiper {
  &-slide {
    width: 100% !important;
    img {
      width: 100%;
    }
  }
  &-button {
    color: $primary-07;
    background-color: transparent;
    border: none;
    font-size: 40px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    //   height: 50px;
    display: flex;
    align-items: center;
  }
}

.home-hero-swiper-button-prev {
  left: 10px;
}
.home-hero-swiper-button-next {
  right: 10px;
}
