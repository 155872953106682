@import "./common/scss/custom";
@import "./common/scss/page-structure";
@import "./common/scss/buttons";
@import "./common/scss/typography";
@import "./common/scss/utilities-classes";
@import "./common/scss/forms";
@import "./common/scss/components";
@import "./common/scss/colors";
@import "./common/scss/box-sizing";
@import "./common/scss/tostify";
@import "./common/scss/video-player";

// @import "./common/scss/colors";

body {
  // padding-top: 76px;
  color: $gray-05;
  // background-color: white !important;
}

.Toastify {
  z-index: 100000;
}
