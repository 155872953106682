@import "../../common/scss/spacing";
@import "../../common/scss/colors";
.ts-seminar {
  &--primary {
    background-color: $primary-02;
    color: $white;
  }
  &--secondary {
    background-color: $secondary-01;
    color: $white;
  }
  &--white {
    background-color: $white;
    color: $gray-05;
  }
  &-card {
    align-items: center;
    &-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: $spacing-06;
      grid-row-gap: $spacing-10;
    }
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $spacing-04;
    &__img {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    &-card {
      &-group {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
