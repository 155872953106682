@import "../../common/scss/colors";
.ts-payment-method {
  padding: 75px 0 172px;

  &__content {
    max-width: 1214px;
    margin: 0 auto;
  }
  &--bg-gray {
    background-color: $gray-09;
  }
}
