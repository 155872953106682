@import "../../common/scss/colors";
@charset "UTF-8";
.fc {
  &-header-toolbar {
    @media (max-width: 575px) {
      flex-direction: column;
      gap: 0.625rem;
    }
  }
  &-col-header-cell-cushion {
    @media (max-width: 575px) {
      font-size: 14px !important;
    }
  }
  &-event-main {
    height: 100%;
    p {
      @media (max-width: 575px) {
        font-size: 10px;
      }
    }
  }
  &-today-button {
    padding: 10px 40px !important;
  }
  &-button {
    &-group {
      gap: 0.5rem;
    }
    &-primary {
      color: #fff;
      background-color: $primary-04 !important;
      border-color: $primary-04 !important;
      border-radius: 50rem !important;
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &-prev-button,
  &-next-button {
    width: 40px;
    height: 40px;
    padding: 0 !important;
    border-radius: 50% !important;
  }
  &-view-harness {
    min-height: 1200px;
    @media (max-width: 991px) {
      min-height: 800px;
    }
    @media (max-width: 767px) {
      min-height: 680px;
    }
    @media (max-width: 575px) {
      min-height: 550px;
    }
    @media (max-width: 475px) {
      min-height: 450px;
    }
  }
  &-col-header-cell-cushion {
    text-decoration: none;
    color: $gray-05;
    font-size: 20px;
  }
  &-day {
    &-today {
      background-color: transparent !important;
      * {
        color: $white !important;
      }
      .fc-daygrid-day-frame {
        border-radius: 10px;
        background-color: $primary-01 !important;
        border: 2px dashed rgb(255, 255, 255) !important;
      }
    }
    &-other {
      .fc-daygrid-day-frame {
        background-color: rgba(83, 96, 158, 0.09);
        border-color: rgba(83, 96, 158, 0.09);
      }
    }
  }

  &-scrollgrid {
    border: none !important;
    td,
    th {
      border: inherit !important;
    }
  }

  &-daygrid {
    &-day {
      padding: 5px !important;
      @media (max-width: 575px) {
        padding: 3px !important;
      }
      &-events {
        position: absolute !important;
        left: -2px;
        top: -2px;
        width: calc(100% + 4px);
        height: 100%;
        z-index: 1;
        .fc-h-event {
          padding-top: 35px;
          padding-bottom: 10px;
          background-color: $primary-07;
          border-color: $primary-07;
          padding-inline: 5px;

          // min-height: 400px;
          min-height: 100%;
          @media (max-width: 575px) {
            padding-block: 20px;

            padding-inline: 1px;
          }
        }
      }
      &-top {
        justify-content: start;
        font-weight: bold;
        position: relative;
        z-index: 2;
        a {
          text-decoration: none;
          color: $gray-05;
          font-size: 20px;
          @media (max-width: 575px) {
            font-size: 14px;
            padding: 0 !important;
          }
        }
      }
      &-frame {
        border: 2px dashed #f990c2;
        border-radius: 10px;
        min-height: 170px !important;
        height: 100%;
        padding: 3px 5px;
        overflow: hidden !important;
        @media (max-width: 991px) {
          min-height: 70px !important;
        }
        @media (max-width: 767px) {
          min-height: 50px !important;
        }
      }
    }
    &-event {
      height: 100%;
      &-harness {
        height: 100%;
      }
    }
  }
}

.ts-event-container {
  height: 100%;
  &__img {
    max-width: 100%;
    max-height: 70px;
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.ts-active {
  .fc-daygrid-day-top {
    a {
      color: white;
    }
  }
}

// .ts-calendar {
//   width: 100%;
//   overflow: hidden;
// }
// .ts-full-calendar {
//   overflow-x: scroll !important;
//   &__wrapper {
//     width: 1000px;
//   }
// }
