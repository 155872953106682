@import "../../common/scss/colors";
@import "../../common/scss/box-sizing";
.ts-hero-logged {
  position: relative;
  // padding: 40px 0 30px;
  padding: 20px 0 30px;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 10;
  }
  margin: 0 auto;
  // @media (max-width: 1400px) {
  //   padding: 40px 0 30px;
  // }
}

.ts-stay-intouch--single {
  max-width: 768px;
  margin: 0 auto;
}

.ts-contact-poster {
  width: 100%;
  height: 100%;
  background-color: $primary-03;
  display: grid;
  place-items: center;
  padding: 3rem 2.5rem;
  min-height: 600px;
}

@media (max-width: 991px) {
  .ts-contact-poster {
    min-height: 400px;
  }
}
