@import "../../common/scss/colors";
@import "../../common/scss/spacing";
.btn {
  &-xl {
    padding: 39px 35px 31px;
    width: 100%;
    max-width: 518px;
    font-weight: bold;
  }
  &-docorated {
    position: relative;
    color: white;
    font-size: 1.6875rem;

    overflow: hidden;
    span {
      position: relative;
      z-index: 2;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: url("../../assets/images/flower-bg-white.svg");
      background-size: 300px;
      background-position: -50px -50px;
      background-repeat: no-repeat;
    }
    &:hover {
      color: white;
    }

    &--green {
      color: white !important;
      border: 1px solid rgb(102, 181, 103);
      background: rgb(102, 181, 103);
      background: -moz-linear-gradient(
        180deg,
        rgba(102, 181, 103, 1) 0%,
        rgba(64, 129, 65, 1) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(102, 181, 103, 1) 0%,
        rgba(64, 129, 65, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(102, 181, 103, 1) 0%,
        rgba(64, 129, 65, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#66b567",endColorstr="#408141",GradientType=1);

      &:hover {
        border: 1px solid rgb(102, 181, 103);
      }
    }
    &--primary {
      border: 1px solid $primary-01;
      background-color: $primary-01;

      &:hover {
        border: 1px solid $primary-01;
        background-color: $primary-01;
      }
    }
  }

  &-primary {
    color: white;
    padding: 6px $spacing-09;
    &:hover {
      color: white;
    }
  }
}
