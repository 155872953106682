// spacing
$spacing-01: 0.25rem; // 4px
$spacing-02: 0.5rem; // 8px
$spacing-03: 0.75rem; // 12px
$spacing-04: clamp(0.75rem, 3.5vw, 1rem); // 16px
$spacing-05: clamp(1rem, 3.5vw, 1.25rem); // 20px
$spacing-06: clamp(1.25rem, 4vw, 1.5rem); // 24px
$spacing-07: clamp(1.5rem, 4vw, 2rem); // 32px
$spacing-08: clamp(2rem, 4.5vw, 2.5rem); // 40px
$spacing-09: clamp(2.5rem, 5vw, 3rem); // 48px
$spacing-10: clamp(3rem, 6vw, 3.375rem); // 54px
$spacing-11: clamp(3.375rem, 6.3vw, 4rem); // 64px
$spacing-12: clamp(4rem, 6.5vw, 4.5rem); // 72px
$spacing-13: clamp(4.5rem, 7vw, 5rem); // 80px
$spacing-14: clamp(4.7rem, 7vw, 6rem); // 96px

.mt {
  &-01 {
    margin-top: $spacing-01;
  }
  &-02 {
    margin-top: $spacing-02;
  }
  &-03 {
    margin-top: $spacing-03;
  }
  &-04 {
    margin-top: $spacing-04;
  }
  &-05 {
    margin-top: $spacing-05;
  }
  &-06 {
    margin-top: $spacing-06;
  }
  &-07 {
    margin-top: $spacing-07;
  }
  &-08 {
    margin-top: $spacing-08;
  }
  &-09 {
    margin-top: $spacing-09;
  }
  &-10 {
    margin-top: $spacing-10;
  }
  &-11 {
    margin-top: $spacing-11;
  }
  &-12 {
    margin-top: $spacing-12;
  }
  &-13 {
    margin-top: $spacing-13;
  }
  &-14 {
    margin-top: $spacing-14;
  }
}
.mb {
  &-01 {
    margin-bottom: $spacing-01;
  }
  &-02 {
    margin-bottom: $spacing-02;
  }
  &-03 {
    margin-bottom: $spacing-03;
  }
  &-04 {
    margin-bottom: $spacing-04;
  }
  &-05 {
    margin-bottom: $spacing-05;
  }
  &-06 {
    margin-bottom: $spacing-06;
  }
  &-07 {
    margin-bottom: $spacing-07;
  }
  &-08 {
    margin-bottom: $spacing-08;
  }
  &-09 {
    margin-bottom: $spacing-09;
  }
  &-10 {
    margin-bottom: $spacing-10;
  }
  &-11 {
    margin-bottom: $spacing-11;
  }
  &-12 {
    margin-bottom: $spacing-12;
  }
  &-13 {
    margin-bottom: $spacing-13;
  }
  &-14 {
    margin-bottom: $spacing-14;
  }
}

.ts-section {
  &-py {
    padding: 80px 0;
  }
}

.mw {
  &-700 {
    max-width: 700px;
  }
  &-1000 {
    max-width: 1000px;
  }
}
