@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@font-face {
  font-family: Century-Gothic;
  src: url("../../assets/fonts/Century-Gothic.ttf");
  font-weight: normal;
}
@font-face {
  font-family: Century-Gothic;
  src: url("../../assets/fonts/gothicb.ttf");
  font-weight: bold;
}
@font-face {
  font-family: GE-Dinar-One;
  src: url("../../assets/fonts/GE-Dinar-One-Light.otf");
  font-weight: normal;
}
@font-face {
  font-family: GE-Dinar-One;
  src: url("../../assets/fonts/GE-Dinar-One-Medium.otf");
  font-weight: bold;
}

// body {
//   font-family: Century-Gothic, "Lato", sans-serif;
// }

.font-family-lato {
  font-family: "Lato", sans-serif;
}

.App {
  font-family: Century-Gothic, "Lato", sans-serif;
}

.ts-rtl {
  * {
    font-family: GE-Dinar-One, "Lato", sans-serif;
  }
}
.ts-ar-font {
  font-family: GE-Dinar-One, "Lato", sans-serif !important;
}
.ts-en-font {
  font-family: Century-Gothic, "Lato", sans-serif !important;
}
// $heading-1: 3.75rem; // 60px
// $heading-2: 3.1875rem; // 51px
// $heading-3: 2.6875rem; // 43px
// $heading-4: 2.25rem; // 36px
// $heading-5: 1.9375rem; // 31px
// $heading-6: 1.5rem; // 24px

$heading-1: clamp(2rem, 4vw, 3.75rem); // 60px
$heading-2: clamp(1.8rem, 4vw, 3.1875rem); // 51px
$heading-3: clamp(1.7rem, 4vw, 2.6875rem); // 43px
$heading-4: clamp(1.5rem, 4vw, 2.25rem); // 36px
$heading-5: clamp(1.4rem, 4vw, 1.9375rem); // 31px
$heading-6: clamp(1.3rem, 4vw, 1.5rem); // 24px

// Description
$desc-1: clamp(1.75rem, 4vw, 2.375rem); // 38px
$desc-2: clamp(1.5rem, 4vw, 1.75rem); // 28px
$desc-3: clamp(1.25rem, 4vw, 1.5rem); // 24px
$desc-4: clamp(1rem, 4vw, 1.25rem); // 20px

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1,
.heading-1 {
  font-size: $heading-1;
}
h2,
.heading-2 {
  font-size: $heading-2;
}
h3,
.heading-3 {
  font-size: $heading-3;
}
h4,
.heading-4 {
  font-size: $heading-4;
}
h5,
.heading-5 {
  font-size: $heading-5;
}
h6,
.heading-6 {
  font-size: $heading-6;
}

.ts-desc {
  &-lg {
    font-size: $desc-1; // 38px
  }
  &-md {
    font-size: $desc-2; // 28px
  }
  &-sm {
    font-size: $desc-3; // 24px
  }
  &-xs {
    font-size: $desc-4; // 20px
  }
}
