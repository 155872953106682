@import "../../common/scss/colors.scss";
.ts-cart {
  $self: &;
  padding: 6.75rem 0 9.375rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  &-container {
    position: relative;
    background-color: $white;
    max-width: 790px;
    min-height: 790px;
    margin: 0 auto;
    display: grid;
    place-items: center;
    text-align: center;
    padding: 1.25rem;
  }
  &__content {
    // width: 553px;
    // max-width: 100%;
  }

  &--empty {
    background-color: $primary-02;
  }
  &--filled {
    #{$self}__img {
      width: clamp(80px, 15vw, 150px);
    }
  }
  &-summery {
    background-color: $gray-08;
    padding: clamp(35px, 4vw, 90px) clamp(35px, 3vw, 70px);
    border-radius: 28px;
    .btn--checkout {
      font-size: 2rem;
    }
  }
}

.ts-payment-methods {
  padding-left: 0;
  list-style: none;
  &__item {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}
