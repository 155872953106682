@import "../../common/scss/colors";
@import "../../common/scss/typography";

.ts-notifications {
  padding: 4rem 0 4rem;
  .accordion {
    &-header {
      position: relative;
      z-index: 2;
    }
    &-button {
      position: relative;
      background-color: $gray-10;
      padding: 1.8rem;
      font-size: $heading-5;
      z-index: 2;
      width: 100%;
      height: 100%;

      // border-radius: 50rem !important;
      transition: background-color 400ms ease-in-out;
      border-radius: 3.2rem 2.2rem 0 0 !important;
      span {
        position: relative;
        z-index: 10;
        color: #6b6b6b;
        padding-right: 50px;
      }
      &:before {
        content: " ";
        position: absolute;
        background-color: $gray-11;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 50rem;
        border-top-right-radius: 0;
        z-index: 1;
      }
      &:after {
        display: flex;
        align-items: center;
        background-image: url("../../assets/icons/minus.svg");
        background-size: 44px 8.52px;
        height: 10px;
        width: 44px;
        position: absolute;
        top: 50%;
        right: 20px;
        z-index: 3;
        transform: translateY(-50%);
      }
      &.collapsed {
        color: #0408ff;
        background-color: transparent;
        &:after {
          height: 44px;
          background-image: url("../../assets/icons/plus.svg");
          background-size: 44px 44px;
          height: 44px;
          width: 44px;
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
    &-body {
      background-color: $gray-10;
      color: $gray-03;
      font-size: $heading-6;
      border-radius: 0 0 2.5rem 2.5rem !important;
    }

    &-item {
      margin-bottom: 1.5rem;
      border: none;

      //   background-color: $gray-10;
    }
  }
  @media (max-width: 991px) {
    padding: 2rem 0 4rem;
    .accordion {
      &-button {
        border-radius: 4rem 4rem 0 0 !important;
      }
    }
  }
  @media (min-width: 1400px) {
    padding: 4rem 3rem 4rem 1rem;
  }
}
