@import "../../common/scss/colors";
@import "../../common/scss/typography";

.ts-faq {
  margin: 9.1875rem 0 12rem;
  // padding-bottom: 2rem;
  .accordion {
    &-header {
      position: relative;
      z-index: 2;
    }
    &-button {
      position: relative;
      background-color: $gray-10;
      font-weight: bold;
      font-size: $heading-5;
      z-index: 2;
      width: 100%;
      height: 100%;

      // border-radius: 50rem !important;
      transition: background-color 100ms ease-in-out;
      transition-delay: 200ms;
      border-radius: 2.2rem 2.2rem 0 0 !important;
      &:not(.collapsed) {
        transition: none;
      }
      span {
        position: relative;
        z-index: 10;
        color: #6b6b6b;
        padding-right: 50px;
      }
      &:before {
        content: " ";
        position: absolute;
        background-color: $gray-11;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 50rem !important;
        z-index: 1;
      }
      &:after {
        display: flex;
        align-items: center;
        background-image: url("../../assets/icons/minus.svg");
        background-size: 44px 8.52px;
        height: 10px;
        width: 44px;
        position: absolute;
        top: 50%;
        right: 20px;
        z-index: 3;
        transform: translateY(-50%);
      }
      &.collapsed {
        color: #0408ff;
        background-color: transparent;
        &:after {
          height: 44px;
          background-image: url("../../assets/icons/plus.svg");
          background-size: 44px 44px;
          height: 44px;
          width: 44px;
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
    &-body {
      background-color: $gray-10;
      color: $gray-03;
      font-size: $heading-6;
      border-radius: 0 0 2.5rem 2.5rem !important;
    }

    &-item {
      margin-bottom: 3.75rem;
      border: none;

      //   background-color: $gray-10;
    }
  }
  @media (max-width: 991px) {
    .accordion {
      &-button {
        border-radius: 4rem 4rem 0 0 !important;
      }
    }
  }
}

.ts-logged-in {
  // padding-bottom: 2rem;
  .ts-faq {
    margin: 1rem 0 2rem;
  }
}
// .accordion:has(.accordion-item) .accordion {
//   color: red !important;
//   background-color: red !important;
// }
