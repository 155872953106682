.ts-programs-menu {
  padding: 140px 0 48px;
  background-image: url("../../assets/images/flower-primary-half.svg");
  background-size: 600px;
  background-repeat: repeat-y;
  &__container {
    display: grid;
    grid-template-columns: 300px 6fr;
    gap: clamp(30px, 4vw, 120px);
    margin-bottom: clamp(40px, 9vw, 170px);
  }

  @media (max-width: 1200px) {
    &__container {
      grid-template-columns: 1fr;
      gap: clamp(24px, 5vw, 50px);
    }
  }
}
