@import "../../common/scss/colors";
.ts-review {
  $use-image-width: clamp(50px, 10vw, 105px);
  padding: 3rem 1rem;
  // border: 2px solid $gray-05;
  border-radius: 7.8125rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  // border: 2px solid $gray-07;
  &__inner {
    display: grid;
    grid-template-columns: $use-image-width auto;
    gap: 1rem;


  }

  &__user-img {
    width: $use-image-width;
  }
  &__children {
    padding: 0;
    padding-top: 2rem;
    list-style: none;
    display: grid;
    gap: 1rem;
    &-item {
      display: grid;
      grid-template-columns: $use-image-width auto;
      gap: 1rem;
          @media (max-width: 767px) {
      gap: 0.5rem;
    }
    }
  }
  &__name {
    font-size: 1.3125rem;
  }
  &__likes {
    font-size: 18px;
    &-thumb {
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    padding: 1rem;
    border-radius: 1rem;
    &__inner {
      grid-template-columns: auto;
      gap: 0.5rem;
    }
  }

  &-post {
    .form-control {
      background-color: $gray-12;
      font-size: 1.75rem;
      padding: 3rem;
      border-radius: 1.8125rem;
      color: $gray-05;
    }
  }

  .ts-review-post {
    .form-control {
      padding: 0.5rem;
      border-radius: 1rem;
      font-size: 1rem;
    }
  }
  @media (min-width: 768px) {
    &__children,
    .ts-review-post {
      margin-left: 150px;
    }
  }
}
