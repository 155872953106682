@import "../../common/scss/utilities-classes";
.ts-about {
  margin: 15rem 0 17rem;
  &__main {
    max-width: 1558px;
    margin: 0 auto;
    display: grid;
    gap: clamp(3rem, 7vw, 7rem);
    align-items: center;
  }

  @media (min-width: 991px) {
    &__main {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.ts-logged-in {
  .ts-about {
    margin: 2.5rem 0 2rem;
    &__main {
      grid-template-columns: 1fr;
    }
  }
}
