@import "../../common/scss/colors.scss";
.ts-hero {
  position: relative;
  width: 100%;
  padding: 70px 0 90px;
  background-color: $primary-02;
  border: 1px solid $border-color-01;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 10;
  }
  &::before {
    content: " ";
    position: absolute;
    height: 900px;
    width: 900px;
    left: 0;
    bottom: 0;
    transform: translate(-10%, 10%);
    background-image: url("../../assets/images/flower-bg-white.svg");
    background-size: cover;
  }

  @media (min-width: 991px) {
    &::after {
      content: " ";
      position: absolute;
      height: 900px;
      width: 900px;
      right: 0;
      top: 0;
      transform: translate(35%, 23%) rotate(243deg);
      background-image: url("../../assets/images/flower-bg-white.svg");
      background-size: cover;
    }
  }
}

.ts-stay-intouch--single {
  max-width: 768px;
  margin: 0 auto;
}

// .ts-logged-in {
//   .ts-hero {
//     @media (min-height: 1119px) and (max-height: 1600px) {
//       &::after {
//         display: none;
//       }
//     }
//   }
// }
