@import "../../common/scss/colors";
.ts-form-subscribe {
  // font-family: "Lato", sans-serif;
  position: relative;
  background-color: $primary-03;
  color: white;
  text-align: center;
  padding: 5rem 2rem;
  display: grid;
  place-items: center;
  // min-height: clamp(450px, 60vw, 700px);
  height: 100%;
  overflow: hidden;
  &__content {
    width: 420px;
    margin: 0 auto;
    max-width: 100%;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  &::after {
    content: " ";
    position: absolute;
    height: 500px;
    width: 500px;
    left: 0;
    bottom: 0;
    transform: translate(-30%, 25%) rotate(-11deg);
    background-image: url("../../assets/images/flower-bg-white.svg");
    background-size: cover;
  }

  .form-control {
    min-height: 67px;
    font-size: 29px;
    padding: 8px 24px;
  }

  .btn-white {
    color: $gray-04 !important;
    &:hover {
      color: white !important;
    }
  }
  // @media (max-width: 1600px) {
  //   min-height: clamp(450px, 40vw, 680px);
  // }
  @media (max-width: 1200px) {
    min-height: clamp(350px, 65vw, 500px);
  }
  @media (max-width: 575px) {
    padding: 5rem 1rem;
    &__content {
      width: 100%;
    }
  }
}
