@import "../../common/scss/colors";
.ts-key-points {
  background-color: $gray-10;
  &-list {
    background-color: $white;
    border-radius: 1rem;
    padding: 1.5rem;
    list-style: none;
    // max-width: 500px;
    li {
      margin-bottom: 1rem;
    }
  }
}

.ts-program-details {
  .ts-fixed-buy-now-button {
    margin-bottom: 1rem;
    .btn {
      padding: 1rem;
      font-size: 16px;
      width: auto;
      width: 100%;
    }
  }
}
.ts-logged-in {
  .ts-program-details {
    .ts-fixed-buy-now-button {
      @media (min-width: 1401px) {
        margin-left: 400px;
      }
    }
  }
}

.ts-rtl {
  .ts-program-details {
    .ts-fixed-buy-now-button {
      @media (min-width: 1401px) {
        margin-left: 0;
      }
    }
  }
}
.ts-logged-in.ts-rtl {
  .ts-program-details {
    .ts-fixed-buy-now-button {
      @media (min-width: 1401px) {
        margin-left: 0;
        margin-right: 400px;
      }
    }
  }
}
