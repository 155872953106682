@import "../../common/scss/colors";
@import "../../common/scss/spacing";
@import "../../common/scss/typography";
@import "../../common/scss/box-sizing";

.ts-side-nav {
  $this: &;
  padding-top: 20px;
  position: relative;
  padding-left: $spacing-05;
  padding-right: $spacing-05;
  padding-bottom: $spacing-08;

  .active-bg {
    background-color: $primary-04;
    color: white;
    padding: 15px 30px 17px;
    &:hover {
      color: white;
    }
  }
  &__content {
    background-color: $gray-08;
    padding: 2rem 1rem;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    .active {
      color: $primary-04;
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ea8db9' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        transform: rotate(180deg);
      }
    }
  }
  &__item {
    padding: 17px 30px 19px;
    color: $gray-04;
    font-size: $heading-6;
    cursor: pointer;
    border-radius: 50rem;
    // margin-bottom: 0.5rem;
    transition: all 300ms ease-in-out;
    a {
      text-decoration: none;
      color: inherit;
    }

    &:hover {
      //   background-color: $primary-04;
      //   color: $white;
      color: $primary-04;
    }
    &--list {
      &:hover {
        color: $gray-04;
      }
    }
  }

  &__link {
    &-list {
      list-style: none;
      display: none;
      padding: 1rem 0 0 0.4rem;
      li {
        padding: 0.4rem 0;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::after {
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          background-size: 20px;
          background-repeat: no-repeat;
          transition: all 300ms ease-in-out;
          transform-origin: center center;
        }
        &:hover {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ea8db9' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
  &__dropdown-container {
    border-radius: 0;
    &:hover {
      background-color: transparent;
    }
  }

  .dropdown {
    background-color: rgba(255, 0, 0, 0) !important;
    &:hover {
    }
    &-toggle {
      color: $gray-04;
      position: relative;
      &::after {
        content: "";
        background: transparent url("../../assets/icons/chevron-down.svg")
          no-repeat right center !important;
        width: 16px;
        height: 16px;
        border: none;
      }
      &:hover {
        color: $primary-04;
      }
    }
    &-menu {
      position: relative !important;
      transform: translate(0px, 0px) !important;
      border: none;
      background-color: inherit;
    }
    &-item {
      color: $gray-04;
      font-size: $heading-6;
      &:hover {
        background-color: inherit;
      }
    }
  }
  &__toggle {
    position: absolute;
    right: 1px;
    top: 0;
    background-color: $gray-08;
    // width: 35px;
    font-weight: bold;
    height: 35px;
    transform: translateX(100%);
    border-radius: 0 0 0.75rem 0;
    display: none;
    border: 2px solid $gray-10;
    border-left: 0;
    color: $gray-06;
    transition: all 400ms ease-in-out;
    svg {
      transition: all 400ms ease-in-out;
    }
    &:focus {
      outline: none;
    }
  }

  .ts-nav-links {
    &:hover {
      color: $primary-04;
    }
  }
  @media (min-width: 1400px) {
    &__content {
      max-height: calc(100vh - 240px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: $gray-08; /* color of the tracking area */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $gray-08; /* color of the scroll thumb */
        border-radius: 8px; /* roundness of the scroll thumb */
        border: 1px solid $gray-08; /* creates padding around scroll thumb */
      }

      &-outer {
        border-radius: 1rem;
        overflow: hidden;
        &-wrapper {
          position: sticky;
          top: 96px !important;
        }
      }
    }
  }
  @media (max-width: 1400px) {
    width: 350px;
    height: calc(100vh - 76px);
    position: fixed;
    top: $nav-bar-height;
    left: 0;
    z-index: 1000;
    padding: 0;
    transform: translate(-100%);
    transition: all 400ms ease-in-out;
    background-color: $gray-08;

    &__toggle {
      display: block;
    }
    &__content {
      width: 100%;
      height: calc(100vh - 76px);
      border-radius: 0 !important;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: $gray-10; /* color of the tracking area */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $gray-07; /* color of the scroll thumb */
        border-radius: 8px; /* roundness of the scroll thumb */
        border: 1px solid $gray-10; /* creates padding around scroll thumb */
      }
    }
  }

  @media (max-width: 991px) {
    #{$this}__item {
      padding: 1rem 2rem;
    }
  }
  @media (max-width: 450px) {
    #{$this}__item {
      padding: 1rem 1rem;
    }
  }
}

.show-sidenav {
  transform: translate(0);

  .ts-side-nav__toggle {
    svg {
      transform: rotate(-180deg);
    }
  }
  @media (max-width: 450px) {
    width: 100%;

    .ts-side-nav__toggle {
      transform: translateX(0);
      right: 6px;
      border: 2px solid $gray-10;
      border-radius: 0.75rem 0 0 0.75rem;
    }
  }
}

.ts-rtl {
  @media (max-width: 1400px) {
    .ts-side-nav {
      left: auto;
      right: 0;
      transform: translate(100%);

      &__toggle {
        right: auto;
        left: 1px;
        transform: translateX(-100%);
        border-radius: 0 0 0 0.75rem;
        border: 2px solid #e2e2e2;
        border-right: 0;
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    .show-sidenav {
      transform: translateX(0) !important;

      .ts-side-nav__toggle {
        left: 6px;
        transform: translateX(0);
        border-radius: 0 0 0.75rem 0;
        border: 2px solid #e2e2e2;
        border-left: 0x;
        svg {
          transform: rotate(0);
        }
      }
    }
  }
}

.ts-side-nav__link-list--course {
}

.ts-nav-active {
  display: block;

  .ts-side-nav__link-list-header {
    // &::after {
    //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ea8db9' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    // }
    a {
      color: $primary-04;
    }
  }
}

.ts-nav-link-active {
  color: $primary-04 !important;
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ea8db9' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    transform: rotate(180deg);
  }
}
