@import "../../common/scss/colors";
@import "../../common/scss/typography";
.payment-invoices {
  padding: 8rem 0 10rem;
  background-color: $primary-02;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-image: url("../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 100%;

  &__details {
    &-container {
      max-width: 50rem;
      margin: 0 auto;
      &-inner {
        background-color: white;
        padding: 6.875rem 1rem 6.25rem;
      }
    }
  }

  &__content {
    max-width: 750px;
    margin-inline: auto;
  }
}
