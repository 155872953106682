@import "../../common/scss/colors";
.ts-promo-gift-footer {
  background-color: $primary-01;
  color: white;
  padding: 50px 0;
  text-align: center;
  a {
    color: white;
  }
}
