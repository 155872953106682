@import "../../common/scss/colors";

.ts-chapters-for-details {
  background-color: $gray-09;
  padding-bottom: 11rem;
  &-cards {
    position: relative;
    top: -55px;
    margin-bottom: 8rem;
  }
  &-card {
    max-width: 334px;
    color: #565656;
    text-decoration: none;
    &:hover {
      color: #565656;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    gap: clamp(2rem, 5vw, 7rem);
  }
}

.ts-chapter-details {
  display: flex;
  flex-direction: row;
  gap: clamp(1rem, 5vw, 7.5rem);
  align-items: center;
  &__img {
    width: 320px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    &__img {
      width: 100%;
    }
  }
}
