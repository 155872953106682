@import "../../common/scss/colors";
@import "../../common/scss/typography";
@import "../../common/scss/spacing";

.ts-checkout-card {
  // width: 392px;
  height: 460px;
  padding: 47px 30px;
  border-radius: clamp(35px, 4vw, 90px);
  border-top-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
}
