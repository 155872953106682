@import "../../common/scss/colors";
.ts-inspiring-stories-card {
  background-color: $primary-07;
  color: $white;
  padding: clamp(0.75rem, 3vw, 2rem);
  border: 2px solid white;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 3px 6px;
  height: 100%;
  &__img {
    width: 100%;
    border-radius: clamp(0.75rem, 4vw, 3rem);
  }
  &-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(0.75rem, 4vw, 3rem);
  }

  @media (max-width: 1600px) {
    &-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 1400px) {
    &-group {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 1200px) {
    &-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 450px) {
    &-group {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
