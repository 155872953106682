$primary-01: #ff99ca;
$primary-02: #eb8eba;
$primary-03: #f883bb;
$primary-04: #ea8db9;
$primary-05: #eea1c5;
$primary-06: #f07bb2;
$primary-07: #f990c2;
$primary-08: #dc92b6;
$success: #1cf395;
$success-02: #4caf50;

$secondary-01: #da3675;
// $green-01:
//
$white: #ffffff;

// Gray
$gray-01: #2d2d2d;
$gray-02: #525252;
$gray-03: #616161;
$gray-04: #767676;
$gray-05: #939597;
$gray-06: #a6a7aa;
$gray-07: #bcbfc2;
$gray-08: #f7f7f7;
$gray-09: #f3f3f3;
$gray-10: #e2e2e2;
$gray-11: #f2f2f2;
$gray-12: #f1f1f1;

$border-color-01: rgba(255, 255, 255, 0.25);
