@import "../../common/scss/utilities-classes";
.ts-confirmation-modal {
  .modal-content {
    padding: 185px 20px 169px;
    @include TsRounded;
    @include TsRoundedNotTopRight;
    background-color: $gray-08;
    text-align: center;

    &-inner {
      max-width: 700px;
      margin: 0 auto;
    }
  }
  .close-btn {
    position: absolute;
    right: 25px;
    top: 25px;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    color: $primary-01;
    font-weight: bold;
    padding: 0;
  }

  .btn-primary-07 {
    padding: 10px 40px !important;
    @media (min-width: 767px) {
      padding: 15px 70px !important;
    }
  }
}
