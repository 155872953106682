@import "../../common/scss/colors";
@import "../../common/scss/typography";
.ts-login,
.ts-register {
  padding: 2.75rem 0 11.875rem;
  background-color: $primary-02;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-image: url("../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto 100%;

  .form-select,
  .form-control {
    color: $gray-03;
  }
  &__form {
    &-container {
      max-width: 58.375rem;
      margin: 0 auto;
      &-inner {
        background-color: white;
        padding: 6.875rem 1.875rem 6.25rem;
      }
    }
    max-width: 630px;
    margin: 0 auto;

    .form-control,
    .form-select {
      border: 2px solid white;
      background-color: $gray-09;
      font-size: $heading-6;
      height: auto;
      padding: 0.88rem 1.25rem 0.98rem 4.5rem;

      &:-webkit-autofill::first-line,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        font-size: $heading-6 !important;
        transition: background-color 50000s ease-in-out 0s;
      }
    }
  }

  .btn-submit {
    position: relative;
    font-weight: bold;
    .btn-img-end {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ts-form-group {
  position: relative;
  &__icon {
    // transform: translateY(-50%);
    width: inherit;
    &-container {
      position: absolute;
      left: 25px;
      top: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}

.invalid-feedback {
  margin: 0.5rem 0 0.5rem 1.5rem;
  font-size: 1rem;
}
