body {
  margin: 0;
  // font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Debuging
// * {
//   background-color: rgba(255, 0, 0, 0.01);
// }
