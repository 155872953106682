@import "../../common/scss/spacing";
@import "../../common/scss/colors";
.ts-profile {
  padding: $spacing-14 0;
  &__main {
  }

  .ts-form-item {
    width: calc(50% - 0.75rem);
    &-group {
      column-gap: 1rem;
      row-gap: 2rem;
    }
  }

  .form {
    &-control,
    &-select {
      background-color: $gray-12;
      min-height: 3rem;
    }
  }

  &-pic {
    position: relative;
    // border-radius: 50%;
    overflow: hidden;
    // background-color: rgb(139, 245, 0);
    cursor: pointer;
  }
  // &__image-upload {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   cursor: pointer;
  //   // opacity: 0;
  // }
  @media (max-width: 575px) {
    .ts-form-item {
      width: 100%;
    }
  }
}
