@import "../../common/scss/colors";
@import "../../common/scss/typography";
@import "../../common/scss/mixins";
@import "../../common/scss/spacing";

.ts-header {
  .navbar {
    background-color: $primary-02;
    &-brand {
      height: 60px;
      padding: 0;
      img {
        height: 100%;
      }
    }
    &-toggler {
      border: 0;
      &:focus {
        box-shadow: none;
      }
      width: 22px;
      height: 16px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        span:nth-child(3) {
          width: 100%;
          margin-left: 0;
        }
      }
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #ffffff;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(2) {
        top: 6px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(3) {
        top: 13px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        width: 70%;
        margin-left: 30%;
        transition: all 0.3s ease-in-out;
      }
      &:not(.collapsed) span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -2px;
        left: 3px;
      }
      &:not(.collapsed) span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:not(.collapsed) span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 15px;
        left: 3px;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .search-group {
    position: relative;
    width: clamp(250px, 23vw, 400px);
    // max-width: 100% !important;
    .form-control {
      background-color: $primary-01;
      color: $white;
      min-height: 48px;
      padding: 0 50px;
      color: $white;
      border: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        // color: white !important;
      }
      &::placeholder {
        color: $white;
      }
    }
    &__img {
      position: absolute;
      right: 20px;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 24px;
      cursor: pointer;
    }

    &__close {
      padding: 0;
      background-color: transparent;
      border: none;
      color: white;
      font-size: 30px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  .ts-header-icon {
    font-size: $heading-6;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 2.1875rem;
    &:hover {
      border: none;
    }
    .title {
      font-size: 1.6875rem;
    }
    &__img {
      width: 30px;
      margin-right: 1rem;
    }

    .badge {
      position: relative;
      top: -14px;

      background-color: $white;
      color: $gray-05;
      min-width: 25px;
      min-height: 25px;
      padding: 7px 0 6px;
      font-size: 12px;
    }
  }

  .form-switch {
    color: white;
  }
  @media (max-width: 1200px) {
    .navbar {
      // padding: 0;
      &-brand {
        padding: 0;
      }
      &-nav {
        padding-top: 20px;
      }
      .search-group {
        width: 100%;
      }
      .nav-item {
        margin-bottom: 1rem;
      }
    }
  }
}

.ts-router-link {
  text-decoration: none;
}

.ts-programs-dropdown {
  .dropdown-toggle {
    min-height: 3rem;
    display: flex;
    align-items: center;
  }
}

.ts-user-details {
  background-color: transparent;
  .dropdown {
    &-toggle {
      padding-left: 0 !important;
      padding-right: 24px !important;
      display: flex;
      align-items: center;
      gap: $spacing-03;
      background-color: transparent !important;
      font-size: $heading-6;
      color: white;
      border: none;
      &::after {
        right: 0;
      }
    }
  }
}

.ts-search-dropdown {
  position: relative;

  &__body {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 12px;
    min-height: 100px;
    max-height: 50vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: $gray-08; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-04; /* color of the scroll thumb */
      border-radius: 10px; /* roundness of the scroll thumb */
    }
    ol {
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      li {
        padding: 7px 0;
      }
    }
  }
  &__program {
    display: grid;
    grid-template-columns: 70px auto;
    gap: 12px;
    align-items: center;
    text-decoration: none;

    &-img {
      width: 100%;
    }
  }
}

.ts-search-courses {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: white;
    /* background remain same */
    transition: background-color 5000s ease-in-out 0s;
  }

  // &:focus {
  //   .ts-search-dropdown__body {
  //     display: none;
  //   }
  // }
}
@media (min-width: 1401px) {
  .ts-user-logged {
    .navbar {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .container {
      max-width: 100%;
    }
  }
}

.ts-rtl {
  .ts-header {
    .search-group {
      &__close {
        left: 10px;
        right: auto;
      }
    }
  }
  #rtlSwitchForm {
    .form-switch {
      padding: 0 40px 0 0;
      position: relative;
      .form-check-input {
        position: absolute;
        right: 0;
      }
    }
  }
}
