@import "./colors";
// @import "./spacing";

/* make the customizations */
$theme-colors: (
  "primary-01": $primary-01,
  "primary-02": $primary-02,
  "primary-03": $primary-03,
  "primary-04": $primary-04,
  "primary-05": $primary-05,
  "primary-06": $primary-06,
  "primary-07": $primary-07,
  "gray-01": $gray-01,
  "gray-02": $gray-02,
  "gray-03": $gray-03,
  "gray-04": $gray-04,
  "gray-05": $gray-05,
  "gray-06": $gray-06,
  "gray-07": $gray-07,
  "gray-08": $gray-08,
  "gray-09": $gray-09,
  "gray-10": $gray-10,
  "gray-11": $gray-11,
  "warning": rgb(201, 214, 16),
  "danger": #dc3545,
  "success": #1cf395,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@media (min-width: 1700px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1675px;
  }
}

.form-control,
.form-select {
  &:focus {
    color: #212529;
    background-color: #ffffff;
    border-color: $primary-01;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 153, 202, 0.25);
  }
}
.accordion-button {
  &:focus {
    box-shadow: none;
  }
}
@media (min-width: 1400px) {
  .ts-logged-in {
    .container {
      width: 100%;
      max-width: 100%;
    }

    // .container {
    //   padding-left: 2vw;
    //   padding-right: 2vw;
    // }
  }
}
// @media (max-width: 1400px) {
//   .w-mob-100 {
//     width: 100%;
//   }
// }

.border-white {
  border: 2px solid white;
}

.form-check-input:checked {
  background-color: $primary-01;
  border-color: $primary-01;
}

.form-check-input:focus {
  border-color: #ff99ca;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 153, 202, 0.25);
}

.dropdown-item.active,
.dropdown-item:active {
  color: white;
  text-decoration: none;
  background-color: $primary-02;
}
